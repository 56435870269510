import React from "react";
import { CircularProgress, Box, styled, Button, Tooltip } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";

const StyledFooterContainer = styled(Box)({
  width: "calc(100% - 48px)",
  flexGrow: 1,
  paddingRight: 32,
  paddingLeft: 16,
  paddingBottom: 4,
  bottom: 0,
  right: 0,
  left: 0,
  height: 60,
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
});

const SyledSaveButton = styled(Button)({});

export interface FooterProps {
  onSubmit: () => void;
  saving?: boolean;
  canSubmit?: boolean;
  alreadyPast?: boolean;
}

export const Footer: React.FC<FooterProps> = ({
  onSubmit,
  saving,
  alreadyPast,
  canSubmit,
}) => {
  let content = null;

  if (saving) {
    content = <CircularProgress size={24} />;
  } else if (alreadyPast) {
    content = (
      <Tooltip placement="top" title="You can not edit content in the past">
        <span>
          <SyledSaveButton
            color="warning"
            disabled
            variant="outlined"
            startIcon={<CloseIcon />}
          >
            Edit Disabled
          </SyledSaveButton>
        </span>
      </Tooltip>
    );
  } else {
    content = (
      <SyledSaveButton
        onClick={onSubmit}
        variant="outlined"
        disabled={!canSubmit}
        startIcon={<SaveIcon />}
      >
        Save Changes
      </SyledSaveButton>
    );
  }

  return <StyledFooterContainer>{content}</StyledFooterContainer>;
};
