import { Model, Response } from "../../types";
import { Content } from "../content";
import { getToken } from "../auth";
import { config } from "../../constants/config";
import { Devotional } from "./types";

export const createDevotional = async (devotional: Partial<Devotional>) => {
  const accessToken = getToken();

  const response = await fetch(`${config.API_URL}/devotional`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
      "ngrok-skip-browser-warning": "skip-browser-warning",
    },
    body: JSON.stringify(devotional),
  });

  if (response.status !== 200) {
    const respJson: Response<void> = await response.json();
    throw new Error(respJson.message);
  }

  const { data }: Response<Content> = await response.json();

  return data;
};
