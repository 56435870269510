import { config } from "../../constants/config";
import { Response } from "../../types";
import { User } from "./types";
import { getToken } from "../auth";

/**
 * Gets a user by their id
 *
 * @param id The user's id
 * @returns User
 */
export async function getUser(id: string, token?: string): Promise<User> {
  const accessToken = token ?? getToken();

  const response = await fetch(`${config.API_URL}/user/${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
      // Uncomment the text below when running locally to avoid errors with ngrok
      'ngrok-skip-browser-warning': 'skip-browser-warning'
    },
  });

  if (response.status !== 200) {
    const respJson: Response<void> = await response.json();

    throw new Error(respJson.message);
  }

  const user: Response<User> = await response.json();

  return user.data;
}

// export async function checkUsernameAvailability(
//   username: string
// ): Promise<boolean> {
//   const accessToken = await AsyncStorage.getItem("AccessToken");
//   const response = await fetch(
//     `${config.API_URL}/user/username-available/${username}`,
//     {
//       method: "GET",
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: `Bearer ${accessToken}`,
//       },
//     }
//   );

//   if (response.status !== 200) {
//     const respJson: Response<void> = await response.json();
//     throw new Error(respJson.message);
//   }

//   const isAvailable: boolean = (await response.json()).data;

//   return isAvailable;
// }

// export async function updateUser(
//   id: string,
//   payload: UpdateUserDto
// ): Promise<User | undefined> {
//   const accessToken = await AsyncStorage.getItem("AccessToken");
//   const response = await fetch(`${config.API_URL}/user/${id}`, {
//     method: "PATCH",
//     headers: {
//       "Content-Type": "application/json",
//       Authorization: `Bearer ${accessToken}`,
//     },
//     body: JSON.stringify(payload),
//   });

//   if (response.status !== 200) {
//     const respJson: Response<void> = await response.json();
//     throw new Error(respJson.message);
//   }

//   const user: Response<User> = await response.json();

//   return user.data;
// }

// export async function deleteUser(id: string): Promise<void> {
//   const accessToken = await AsyncStorage.getItem("AccessToken");
//   const response = await fetch(`${config.API_URL}/user/${id}`, {
//     method: "DELETE",
//     headers: {
//       "Content-Type": "application/json",
//       Authorization: `Bearer ${accessToken}`,
//     },
//   });

//   if (response.status !== 204) {
//     const respJson: Response<void> = await response.json();
//     throw new Error(respJson.message);
//   }
// }

// export async function uploadProfileImage(
//   id: string,
//   uri: string
// ): Promise<User> {
//   const accessToken = await AsyncStorage.getItem("AccessToken");

//   const file = {
//     uri,
//     type: "image/jpeg",
//     name: `${id}-profile`,
//   };

//   const formData = new FormData();

//   // FormData doesn't seem to like the `file` object, but this works anyway
//   // https://stackoverflow.com/a/36124650/4107095
//   // @ts-expect-error file is not a Blob
//   formData.append("file", file);

//   const response = await fetch(`${config.API_URL}/user/${id}/image`, {
//     method: "POST",
//     headers: {
//       Authorization: `Bearer ${accessToken}`,
//       "Content-Type": "multipart/form-data",
//     },
//     body: formData,
//   });

//   if (response.status !== 200) {
//     const respJson: Response<void> = await response.json();
//     throw new Error(respJson.message);
//   }

//   const user: Response<User> = await response.json();

//   return user.data;
// }

// export async function getPushTokensForUser(id: string): Promise<PushToken[]> {
//   const accessToken = await AsyncStorage.getItem("AccessToken");
//   const response = await fetch(`${config.API_URL}/user/${id}/push-tokens`, {
//     method: "GET",
//     headers: {
//       "Content-Type": "application/json",
//       Authorization: `Bearer ${accessToken}`,
//     },
//   });

//   if (response.status !== 200) {
//     const respJson: Response<void> = await response.json();
//     throw new Error(respJson.message);
//   }

//   const { data }: Response<PushToken[]> = await response.json();

//   return data;
// }

// export async function addPushTokenForUser(
//   id: string,
//   payload: AddPushTokenDto
// ): Promise<PushToken> {
//   const accessToken = await AsyncStorage.getItem("AccessToken");
//   const response = await fetch(`${config.API_URL}/user/${id}/push-tokens`, {
//     method: "POST",
//     headers: {
//       "Content-Type": "application/json",
//       Authorization: `Bearer ${accessToken}`,
//     },
//     body: JSON.stringify(payload),
//   });

//   if (response.status !== 201) {
//     const respJson: Response<void> = await response.json();
//     throw new Error(respJson.message);
//   }

//   const { data }: Response<PushToken> = await response.json();

//   return data;
// }

// export async function getGroupForUser(id: string): Promise<Group | null> {
//   const accessToken = await AsyncStorage.getItem("AccessToken");
//   const response = await fetch(`${config.API_URL}/user/${id}/group`, {
//     method: "GET",
//     headers: {
//       "Content-Type": "application/json",
//       Authorization: `Bearer ${accessToken}`,
//     },
//   });

//   if (response.status !== 200) {
//     const respJson: Response<void> = await response.json();
//     throw new Error(respJson.message);
//   }

//   const group: Response<Group | null> = await response.json();

//   return group.data;
// }
