import React, { useCallback, useMemo } from "react";
import Box from "@mui/material/Box";
import {
  Badge,
  Stack,
  ListItem,
  ListItemText,
  styled,
  ListItemIcon,
  Pagination,
  Button,
} from "@mui/material";
import {
  RadioButtonUncheckedRounded,
  CheckCircleRounded,
} from "@mui/icons-material";
import { Content, CONTENT_TYPE } from "../../services/content";
import { useContentModal } from "../../context/content-modal-context";
import { useContentList } from "../../context/content-list-context";
import { Devotional } from "../../services/devotional";

const StyledRoot = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  marginTop: "80px",
  alignItems: "center",
  paddingBottom: "10px",
  flexDirection: "column",
  justifyContent: "center",
  overflowY: "auto",
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const StyledList = styled(Stack)(({ theme }) => ({
  width: "100%",
  maxWidth: "50%",
  minWidth: "640px",
  marginBottom: "20px",
}));

const StyledListItem = styled(ListItem)(({ theme }) => ({
  borderRadius: 10,
  border: `1px solid ${theme.palette.divider}`,
}));

const StyledListItemText = styled(ListItemText)(({ theme }) => ({
  flex: 2,
}));

const StyledListItemMenu = styled(Box)(({ theme }) => ({
  justifyContent: "flex-end",
  alignItems: "flex-end",
  display: "flex",
  flexGrow: 3,
}));

const StyledListItemMenuBadge = styled(Badge)(({ theme }) => ({}));

const StyledPagination = styled(Pagination)(({ theme }) => ({
  width: "100%",
  bottom: 0,
  display: "flex",
  paddingBottom: "10px",
  position: "fixed",
  justifyContent: "center",
  backgroundColor: "white",
}));

const contentTypes = ["verse", "prayer", "reflection"];

const DayListItem: React.FC<{ day: Date; contents?: Content[] }> = ({
  day,
  contents,
}) => {
  const { handleOpen } = useContentModal();

  const contentHasAllTypes = useMemo(() => {
    return contentTypes.every((type) =>
      contents?.some((content) => content.type === type),
    );
  }, [contents]);

  const handleClick = useCallback(() => {
    const verse = contents?.find((c) => c.type === CONTENT_TYPE.verse) || null;
    const reflection =
      contents?.find((c) => c.type === CONTENT_TYPE.reflection) || null;
    const prayer =
      contents?.find((c) => c.type === CONTENT_TYPE.prayer) || null;

    const devotional: Partial<Devotional> = {
      date: day,
      imageUrl: null,
      verse: verse!!,
      reflection: reflection!!,
      prayer: prayer!!,
    };

    handleOpen(day, devotional!!);
  }, [handleOpen, day, contents]);

  // const handleClick = useCallback(
  //     (type: string) => {
  //       const contentOfType = contents?.find((content) => content.type === type);
  //
  //       handleOpen(type, day, contentOfType);
  //     },
  //     [handleOpen, day, contents]
  // );

  return (
    <StyledListItem>
      <StyledListItemText>{day.toDateString()}</StyledListItemText>
      <StyledListItemMenu>
        <StyledListItemMenuBadge>
          <Button
            variant="outlined"
            onClick={() => handleClick()}
            color="warning"
            fullWidth
          >
            Create Devotional
          </Button>
        </StyledListItemMenuBadge>
        {/*{contentTypes.map((type, index) => {*/}
        {/*  const contentOfType = contents?.find(*/}
        {/*      (content) => content.type === type*/}
        {/*  );*/}

        {/*  return (*/}
        {/*      <StyledListItemMenuBadge*/}
        {/*          badgeContent={contentOfType?.likes ?? 0}*/}
        {/*          color="success"*/}
        {/*      >*/}
        {/*        <Button*/}
        {/*            variant="outlined"*/}
        {/*            onClick={() => handleClick(type)}*/}
        {/*            color={contentOfType ? "success" : "warning"}*/}
        {/*        >*/}
        {/*          {type}*/}
        {/*        </Button>*/}
        {/*      </StyledListItemMenuBadge>*/}
        {/*  );*/}
        {/*})}*/}
      </StyledListItemMenu>
      <ListItemIcon style={{ flex: 1, justifyContent: "flex-end" }}>
        {contentHasAllTypes ? (
          <CheckCircleRounded color="success" />
        ) : (
          <RadioButtonUncheckedRounded color="disabled" />
        )}
      </ListItemIcon>
    </StyledListItem>
  );
};

export interface FilterContent {
  year?: number;
  notCompletedYet?: boolean;
  fromTodayOnwards?: boolean;
}

export interface ContentItem {
  contentsOfTheDay: Content[];
  day: Date;
  index: number;
}

export const DailyContentList: React.FC = () => {
  const { onPageChange, page, getPageContent, pageCount } = useContentList();

  return (
    <StyledRoot>
      <StyledList gap={1}>
        {getPageContent().map((contentItem, index) => (
          <DayListItem
            key={index}
            day={contentItem.day}
            contents={contentItem.contents}
          />
        ))}
      </StyledList>
      <StyledPagination count={pageCount} page={page} onChange={onPageChange} />
    </StyledRoot>
  );
};
