import React from "react";
import { Box, styled, IconButton, Typography } from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

const StyledHeaderContainer = styled(Box)({
  // backgroundColor: "blue",
  // position: "fixed",
  width: "calc(100% - 48px)",
  flexGrow: 1,
  paddingTop: 8,
  paddingRight: 16,
  paddingLeft: 32,
  top: 0,
  right: 0,
  left: 0,
  height: 60,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
});

export interface HeaderProps {
  title: string;
  onClose: () => void;
}

export const Header: React.FC<HeaderProps> = ({ title, onClose }) => {
  return (
    <StyledHeaderContainer>
      <Typography variant="h5">{title}</Typography>
      <IconButton aria-label="delete" onClick={onClose}>
        <CloseRoundedIcon />
      </IconButton>
    </StyledHeaderContainer>
  );
};
