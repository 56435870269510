import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useState,
} from "react";

import {
  Content,
  CONTENT_TYPE,
  createContent,
  updateContent as patchContent,
} from "../../services/content";
import { padWithZero, toDateOnly, toDateString } from "../../services/date";
import { createDevotional, Devotional } from "../../services/devotional";

interface ContentModalContextType {
  open: boolean;
  dateString: string;
  isPast: boolean;
  saving: boolean;
  contentDay: Date | null;
  content: Content | null;
  contentType: string | null;
  handleClose: () => void;
  handleSave: () => Promise<void>;
  // handleOpen: (type: string, day: Date, contentToOpen?: Content) => void;
  handleOpen: (day: Date, devotionalToOpen?: Partial<Devotional>) => void;
  updateContent: (updatedContentField: Partial<Content>) => void;
  upsertContent: (content: Partial<Content>) => Promise<void>;
  updateDevotional: (updatedDevotionalField: Partial<Devotional>) => void;
  devotional: Partial<Devotional> | null;
}

export const ContentModalContext = React.createContext<
  ContentModalContextType | undefined
>(undefined);

export const ContentModalProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const [saving, setSaving] = useState<boolean>(false);
  const [isPast, setIsPast] = useState<boolean>(false);
  const [dateString, setDateString] = useState<string>("NO DATE");
  const [contentType, setContentType] = useState<string | null>(null);
  const [contentDay, setContentDay] = useState<Date | null>(null);
  const [content, setContent] = useState<Content | null>(null);
  const [open, setOpen] = useState(false);
  const [devotional, setDevotional] = useState<Partial<Devotional> | null>(
    null,
  );

  // const upsertContent = useCallback(async () => {
  //   if (content) {
  //     setSaving(true);
  //
  //     let updatedContent: Content = content;
  //
  //     const feelGoodPromise = new Promise((resolve) =>
  //       setTimeout(resolve, 1000)
  //     );
  //
  //     const doUpsert = async () => {
  //       if (content.id) {
  //         updatedContent = await patchContent(content);
  //       } else {
  //         updatedContent = await createContent({
  //           ...content,
  //           type: (contentType || "") as any,
  //           date: toDateString(contentDay as any) as any,
  //         });
  //       }
  //     };
  //
  //     await Promise.all([feelGoodPromise, doUpsert()]);
  //
  //     setContent(updatedContent);
  //
  //     setSaving(false);
  //   }
  // }, [content, contentDay, contentType]);

  // const handleOpen = useCallback(
  //   (type: string, day: Date, contentToOpen?: Content) => {
  //     setContentType(type);
  //     setContentDay(day);
  //     setContent(contentToOpen || null);
  //
  //     setOpen(true);
  //   },
  //   []
  // );

  const upsertContent = useCallback(async () => {
    if (content) {
      console.log("tem content");
      setSaving(true);

      let updatedContent: Content = content;

      const feelGoodPromise = new Promise((resolve) =>
        setTimeout(resolve, 1000),
      );

      const doUpsert = async () => {
        if (content.id) {
          updatedContent = await patchContent(content);
        } else {
          updatedContent = await createContent({
            ...content,
            type: (contentType || "") as any,
            date: toDateString(contentDay as any) as any,
          });
        }
      };

      await Promise.all([feelGoodPromise, doUpsert()]);

      setContent(updatedContent);

      setSaving(false);
    } else {
      setSaving(true);

      if (!devotional) {
        return;
      }

      await createDevotional({
        date: toDateString(contentDay as any) as any,
        imageUrl: devotional.imageUrl,
        verse: {
          type: CONTENT_TYPE.verse,
          date: toDateString(contentDay as any) as any,
          ...devotional.verse,
        } as Content,
        reflection: {
          type: CONTENT_TYPE.reflection,
          date: toDateString(contentDay as any) as any,
          ...devotional.reflection,
        } as Content,
        prayer: {
          type: CONTENT_TYPE.prayer,
          date: toDateString(contentDay as any) as any,
          ...devotional.prayer,
        } as Content,
      });

      setSaving(false);
    }
  }, [content, contentDay, contentType, devotional]);

  const handleOpen = useCallback(
    (day: Date, devotionalToOpen?: Partial<Devotional>) => {
      setDevotional(devotionalToOpen || null);
      setContentDay(day);

      setOpen(true);
    },
    [],
  );

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleSave = useCallback(async () => {
    await upsertContent();
  }, [upsertContent]);

  const updateContent = useCallback(
    (updatedContentField: Partial<Content>) => {
      let newContent: Content;

      if (content) {
        newContent = { ...content, ...updatedContentField };
      } else {
        newContent = { ...updatedContentField } as Content;
      }

      setContent(newContent);
    },
    [content],
  );

  const updateDevotional = useCallback(
    (updatedDevotionalField: Partial<Devotional>) => {
      let newDevotional: Partial<Devotional>;

      if (devotional) {
        newDevotional = { ...devotional, ...updatedDevotionalField };
      } else {
        newDevotional = { ...updatedDevotionalField } as Devotional;
      }

      setDevotional(newDevotional);
    },
    [devotional],
  );

  useEffect(() => {
    if (contentDay) {
      const today = new Date();
      const [year, month, day] = toDateString(contentDay).split("-");

      const todayDateonly = toDateOnly(today);
      const contentDateonly = toDateOnly(contentDay);

      const isInThePast = todayDateonly > contentDateonly;

      setDateString(`${padWithZero(month)}/${padWithZero(day)}/${year}`);

      setIsPast(isInThePast);
    }
  }, [contentDay]);

  return (
    <ContentModalContext.Provider
      value={{
        open,
        dateString,
        isPast,
        handleOpen,
        handleClose,
        content,
        contentDay,
        contentType,
        updateContent,
        upsertContent,
        handleSave,
        saving,
        updateDevotional,
        devotional,
      }}
    >
      {children}
    </ContentModalContext.Provider>
  );
};
